<template>
    <div class="px-3 md:px-24 md:-mt-3">
        <div class="hero bg-red-500 h-20 -mx-3 rounded-b-3xl"></div>
        <div class="profil p-3 w-4/5 md:w-4/5 bg-white shadow-lg rounded-3xl mx-auto -mt-10 flex" v-if="userLogin != null">
            <div class="w-20 flex-none h-20 rounded-xl overflow-hidden relative" @click="modalShowImage = true">
                <div class="w-20 h-20 z-10 absolute bg-black opacity-0 hover:opacity-80 flex items-center cursor-pointer" @click="modalShowImage = true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </div>
                <img :src="userLogin.user.avatar" class="w-full" />
            </div>
            <div class=" flex-grow ml-2 truncate overflow-ellipsis overflow-hidden">
                <p class="mt-3 text-lg leading-2 font-semibold tracking-tight text-gray-900">
                    {{ userLogin.user.nama_lengkap }}
                </p>
                <p class="tracking-tight text-gray-500 truncate">
                    {{ userLogin.user.email }}
                </p>
            </div>
        </div>

        <router-view class="mt-4"></router-view>
        <loading-modal :isShow="loadingAvatar"/>

        <modal :isOpen="modalShowImage">
            <img :src="userLogin.user.avatar" class="w-56 rounded-lg mx-auto mb-2"/>
            <div class="flex space-x-1">
                <div class="flex-grow px-3 py-2 bg-blue-400 text-white rounded-lg mt-1 text-center cursor-pointer hover:bg-blue-500" @click="openFilePicker">Ganti Foto</div>
                <button @click="modalShowImage = false" class="px-3 py-2 bg-gray-100 text-gray-800 rounded-lg mt-1 text-center cursor-pointer hover:bg-gray-200 flex-grow">Tutup</button>
            </div>
        </modal>

        <avatar-cropper
            upload-file-field="fileUpload"
            :upload-handler="uploadFileAvatar"
            v-model="avatarUploadTrigger"
        />


    </div>
</template>
<script>
    import AvatarCropper from 'vue-avatar-cropper'
    import LoadingModal from '../../components/LoadingModal.vue';
    import Modal from '../../components/Modal.vue';
    import { errorHandler, showErrorNotif, showSuccessNotif } from '../../helpers/Tools';
    export default {
        name: 'ProfilMain',
        components : {
            LoadingModal,
                Modal,
                AvatarCropper
        },
        data () {
            return {
				loadingAvatar : false,
                modalShowImage : false,
                avatarUploadTrigger : false
            }
        },
        computed: {
			userLogin () {
				return this.$store.getters.userLogin
            }
        },

        methods: {
            openFilePicker () {
                this.avatarUploadTrigger = true
                this.modalShowImage = false
            },
            async uploadFileAvatar(cropper) {
                this.loadingAvatar = true;
                this.modalShowImage = false;
                try {
                    
                    let formData = new FormData();
                    let fileUploadUrl = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime)
                    let fileResponse = await fetch(fileUploadUrl);
                    let fileBlob = await fileResponse.blob();
                    let fileUpload = new File([fileBlob], "Avatar Image", { type: 'image/png' });
                    formData.append('fileUpload', fileUpload);

                    let res = await this.$store.dispatch("gantiAvatar", {
                        formData : formData
                    });

                    this.loadingAvatar = false;
                    let result = res.data;
                    if(result.status == 'success') {
                        showSuccessNotif(this, result.message);
                        var userData            = result.data
                        var userDataGetter      = this.$store.getters.userLogin
                            userDataGetter.user = userData
						this.$store.dispatch('actionLoginUser', userDataGetter)
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.loadingAvatar = false;
                    errorHandler(this, error);
                }

            }
        }

    }
</script>
